import { Link } from 'gatsby'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'

import { Button, Intro, Label, Overlay } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import ModalCard from '../../components/ModalCard'
import border from '../../constants/border'
import colors from '../../constants/colors'
import CheckoutSection from '../../sections/CheckoutSection'

import { ProductData } from './types'
import { useStripeSubmit } from './useStripeSubmit'

const OptionSelect = styled.select`
  height: 30px;
  border: 1px solid ${colors.pink};
  border-radius: ${border.LARGE_RADIUS};
  margin-right: 10px;
  padding: 0 14px;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 200;
  line-height: 24px;
  color: ${colors.black};
  background-color: ${colors.white};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: lightgray;
  }
`

const Price = styled.span`
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
`

const Content = styled.div`
  width: 100%;
  margin-top: 35px;
`

const Category = styled.div`
  margin-bottom: 35px;
`

const CategoryHeader = styled.div`
  text-align: left;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 14px;
`

const Description = styled(Intro)`
  margin-left: 0;
`

const Disclaimer = styled.p`
  width: 100%;
  margin-bottom: 28px;
  line-height: 24px;
`
const Error = styled.div`
  color: ${colors.errorRed};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 35px;
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalButton = styled.button`
  /* reset button style */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: ${colors.pink};
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
`

type Props = {
  productId: number
  description: string
  validity_months: number
  transportation: string
  options: {
    optionName: string
    displayName: string
    priceCents: number
  }[]
  cgv: string
  confidentialite: string
}

const GiftCardForm: FC<Props> = ({
  productId,
  description,
  validity_months,
  transportation,
  options,
  cgv,
  confidentialite
}) => {
  const defaultOption = options[0]
  const [isCGVModalOpen, setIsCGVModalOpen] = useState(false)
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false)

  const [product, setProduct] = useState<ProductData>({
    product_id: productId,
    option: defaultOption.optionName,
    customer_email: null,
    customer_phone: null,
    from: null,
    to: null,
    message: null
  })

  const setProductData = (data: Partial<ProductData>) => {
    setProduct({ ...product, ...data })
  }

  const handleSelectOption = (optionName: string) => {
    setProductData({ option: optionName })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setProductData({ [name]: value })
  }

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target
    setProductData({ [name]: value })
  }

  const selectedOption = options.find(
    option => option.optionName === product.option
  )!
  const priceCents = selectedOption?.priceCents!
  const [error, setError] = useState<string | null>(null)
  const { handleSubmit, isLoading } = useStripeSubmit(product, setError)

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Content>
          <Category>
            <CategoryHeader>Description&nbsp;:</CategoryHeader>
            <Description>
              <MarkdownWrapper>{description}</MarkdownWrapper>
            </Description>
          </Category>
          <Category>
            <CategoryHeader>Date de validité&nbsp;:</CategoryHeader>
            <Description>
              Ce bon cadeau est valable {validity_months} mois.
            </Description>
          </Category>
          <Category>
            <CategoryHeader>Détails&nbsp;:</CategoryHeader>
            <Description>{transportation}</Description>
          </Category>
          <Category>
            <CategoryHeader>Choisissez votre option&nbsp;:</CategoryHeader>

            <OptionSelect
              onChange={event => handleSelectOption(event.target.value)}
              disabled={options.length === 1}
              value={selectedOption?.optionName}
            >
              {options.map(option => (
                <option key={option?.optionName} value={option?.optionName}>
                  {option?.displayName}
                </option>
              ))}
            </OptionSelect>
            <Price>Prix : {priceCents / 100}€ TTC</Price>
          </Category>
          <Category>
            <CategoryHeader>Vos informations&nbsp;:</CategoryHeader>
            <p>
              <Label htmlFor='customer_first_name'>
                Prénom *
                <br />
                <input
                  type='name'
                  name='customer_first_name'
                  id='customer_first_name'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
            <p>
              <Label htmlFor='customer_last_name'>
                Nom de famille *
                <br />
                <input
                  type='lastname'
                  name='customer_last_name'
                  id='customer_last_name'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
            <p>
              <Label htmlFor='customer_email'>
                Adresse email *
                <br />
                <input
                  type='email'
                  name='customer_email'
                  id='customer_email'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
            <p>
              <Label htmlFor='customer_phone'>
                Numéro de téléphone *
                <br />
                <input
                  type='phone'
                  name='customer_phone'
                  id='customer_phone'
                  pattern='^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
          </Category>
          <Category>
            <CategoryHeader>
              Informations qui apparaîtront sur le bon cadeau :
            </CategoryHeader>
            <p>
              <Label htmlFor='phone'>
                De la part de *
                <br />
                <input
                  type='text'
                  name='from'
                  id='from'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
            <p>
              <Label htmlFor='phone'>
                Cadeau à l'attention de *
                <br />
                <input
                  type='text'
                  name='to'
                  id='to'
                  onChange={handleChange}
                  required
                />
              </Label>
            </p>
            <p>
              <Label htmlFor='message'>
                Message personnalisé (250 caractères maximum)
                <br />
                <textarea
                  name='message'
                  id='message'
                  placeholder='Vous pouvez écrire un message personnalisé ici.'
                  onChange={handleTextAreaChange}
                  style={{ height: 200 }}
                  maxLength={250}
                />
              </Label>
            </p>
          </Category>
          <CategoryHeader>
            Vos informations de carte bancaire 💳 :
          </CategoryHeader>
          <p style={{ marginBottom: 21, lineHeight: '24px' }}>
            Paiement sécurisé avec{' '}
            <a href='https://stripe.com/fr' target='_blank' rel='noreferrer'>
              stripe.com
            </a>
            . Pour d'autres moyens de paiement,{' '}
            <Link to='/contact/'>contactez-moi</Link>.
          </p>
          <CheckoutSection resetError={() => setError(null)} />
          <p style={{ marginBottom: 28 }}>* Champs obligatoires</p>
          <Disclaimer>
            En continuant, vous acceptez nos{' '}
            <ModalButton type='button' onClick={() => setIsCGVModalOpen(true)}>
              CGV
            </ModalButton>{' '}
            et notre{' '}
            <ModalButton
              type='button'
              onClick={() => setIsPrivacyModalOpen(true)}
            >
              politique de confidentialité
            </ModalButton>
            .
            <br />
            Vous recevrez un email de confirmation de votre commande ainsi qu'un
            bon cadeau au format PDF à imprimer.
          </Disclaimer>
          {error && <Error>{error}</Error>}
          <SubmitButtonContainer>
            <Button type='submit' large disabled={isLoading}>
              {isLoading
                ? 'Paiement en cours...'
                : `Payer ${priceCents / 100}€ TTC`}
            </Button>
          </SubmitButtonContainer>
        </Content>
      </form>
      {isCGVModalOpen && (
        <Overlay
          style={{ pointerEvents: 'auto' }}
          onClick={() => setIsCGVModalOpen(false)}
        >
          <ModalCard
            handleCloseClick={() => setIsCGVModalOpen(false)}
            title='Conditions générales de vente'
          >
            {cgv}
          </ModalCard>
        </Overlay>
      )}
      {isPrivacyModalOpen && (
        <Overlay
          style={{ pointerEvents: 'auto' }}
          onClick={() => setIsPrivacyModalOpen(false)}
        >
          <ModalCard
            handleCloseClick={() => setIsPrivacyModalOpen(false)}
            title='Politique de confidentialité'
          >
            {confidentialite}
          </ModalCard>
        </Overlay>
      )}
    </>
  )
}

export default GiftCardForm
