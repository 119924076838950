import styled from 'styled-components'

import { Title } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import colors from '../../constants/colors'
import shadow from '../../constants/shadow'
import spacing from '../../constants/spacing'

export const ModalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
  overflow: hidden;
  box-shadow: ${shadow.LIGHT_BOX_SHADOW};
  height: 100%;
  max-width: 800px;

  @media ${breakpoint.TABLET} {
    margin: 14px;
    border-radius: 20px;
    height: inherit;
    max-height: calc(100% - 28px - 35px - ${spacing.MAIN_PADDING});
    top: ${spacing.MAIN_PADDING};
    position: absolute;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 ${spacing.MAIN_PADDING} ${spacing.MAIN_PADDING};
`

export const TitleBar = styled.div`
  padding: ${spacing.MAIN_PADDING};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardTitle = styled(Title)`
  padding-bottom: 0;
  flex: 1;
  padding-right: 14px;
`

export const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`
