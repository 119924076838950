import { FC, useEffect } from 'react'

import CloseIcon from '../../icons/CloseIcon'
import MarkdownWrapper from '../MarkdownWrapper'

import {
  CardTitle,
  CloseButton,
  ContentContainer,
  MainContent,
  ModalCardContainer,
  TitleBar
} from './ModalCard.styles'

type Props = {
  handleCloseClick: () => void
  title: string
  children: string
}
const ModalCard: FC<Props> = ({ handleCloseClick, title, children }) => {
  // Add class to body to prevent scrolling when modal is opened and remove it when closed.
  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => document.body.classList.remove('modal-open')
  })

  // Call handleCloseClick when the user hits the escape key.
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) =>
      e.key === 'Escape' && handleCloseClick()

    document.addEventListener('keydown', handleEscape)

    return () => document.removeEventListener('keydown', handleEscape)
  })

  return (
    <ModalCardContainer onClick={e => e.stopPropagation()}>
      <ContentContainer>
        <TitleBar>
          <CardTitle centered>{title}</CardTitle>
          <CloseButton type='button' onClick={handleCloseClick}>
            <CloseIcon />
          </CloseButton>
        </TitleBar>

        <MainContent>
          <MarkdownWrapper>{children}</MarkdownWrapper>
        </MainContent>
      </ContentContainer>
    </ModalCardContainer>
  )
}

export default ModalCard
