/* eslint-disable react/no-unknown-property */
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { ButtonLink, ContainerCard, TitleLarge } from '../../common/styles'
import border from '../../constants/border'
import breakpoint from '../../constants/breakpoint'
import spacing from '../../constants/spacing'
import Layout from '../../layouts'

import GiftCardForm from './GiftCardForm'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY!, {
  locale: 'fr'
})

const Illustration = styled(GatsbyImage)<{
  width: number
  height: number
}>`
  width: ${({ width }) => width / 1.5}px;
  height: ${({ height }) => height / 1.5}px;
  max-width: 100%;
  border-radius: ${border.LARGE_RADIUS};
  object-fit: cover;
  object-position: center center;
  margin-bottom: ${spacing.MAIN_PADDING} 0;

  @media ${breakpoint.TABLET} {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BackButton = styled(ButtonLink)`
  display: inline-flex;
  margin-top: ${spacing.MEDIUM_PADDING};
  margin-bottom: ${spacing.MEDIUM_PADDING};
  margin-left: ${spacing.MEDIUM_PADDING};
`

const BackButtonText = styled.span`
  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 1000px;
`

const Card = styled(ContainerCard)`
  margin: 0px 35px 35px;
  width: 100%;
`

const BackArrow = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z'
      fill='currentColor'
    />
  </svg>
)

const OrderPage = ({
  data: { strapiProduct, strapiGlobal }
}: PageProps<Queries.ProductQuery>) => {
  const productId = strapiProduct?.strapi_id!

  const imageHeight =
    strapiProduct?.image?.localFile?.childImageSharp?.gatsbyImageData.height!
  const imageWidth =
    strapiProduct?.image?.localFile?.childImageSharp?.gatsbyImageData.width!
  const imageRatio = imageWidth / imageHeight
  const illustrationHeight = imageHeight
  const illustrationWidth = illustrationHeight * imageRatio
  const imageData = strapiProduct?.image
    ?.localFile as unknown as IGatsbyImageData
  const illustrationImage = getImage(imageData)

  return (
    <Elements stripe={stripePromise}>
      <Layout
        smallNavBar
        transparentBg={false}
        seo={{
          title: strapiProduct?.seo?.title,
          description: strapiProduct?.seo?.description
        }}
      >
        <Container>
          <Nav>
            <BackButton to='/boutique/' outline>
              <BackArrow />
              <BackButtonText>Retour aux cartes cadeaux</BackButtonText>
            </BackButton>
          </Nav>
          <Card>
            <TitleLarge>{strapiProduct?.title}</TitleLarge>
            {illustrationImage && (
              <Illustration
                image={illustrationImage}
                alt={
                  strapiProduct?.image?.alternativeText ||
                  `Illustration pour le bon cadeau ${strapiProduct?.title}`
                }
                height={illustrationHeight}
                width={illustrationWidth}
              />
            )}
            <GiftCardForm
              productId={productId}
              description={
                strapiProduct?.longDescription?.data?.longDescription!
              }
              options={
                strapiProduct?.options as {
                  optionName: string
                  displayName: string
                  priceCents: number
                }[]
              }
              validity_months={strapiProduct?.validity_months!}
              transportation={strapiProduct?.transportation!}
              cgv={strapiGlobal?.cgv?.data?.cgv!}
              confidentialite={
                strapiGlobal?.confidentialite?.data?.confidentialite!
              }
            />
          </Card>
        </Container>
      </Layout>
    </Elements>
  )
}

export default OrderPage

export const query = graphql`
  query Product($id: String) {
    strapiProduct(id: { eq: $id }) {
      seo {
        description
        title
      }
      options {
        priceCents
        optionName
        displayName
      }
      title
      updatedAt
      strapi_id
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 300, formats: [AUTO, WEBP])
          }
        }
        alternativeText
      }
      longDescription {
        data {
          longDescription
        }
      }
      validity_months
      transportation
    }
    strapiGlobal {
      cgv {
        data {
          cgv
        }
      }
      confidentialite {
        data {
          confidentialite
        }
      }
    }
  }
`
