import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import { FormEvent, useState } from 'react'

import { ProductData } from './types'

const endpoint = `${process.env.GATSBY_API_URL}/generate-gift-card`

const errorMessage =
  "Une erreur est survenue. Votre carte n'a pas été débitée. Réessayez plus tard ou contactez-moi pour réserver votre bon cadeau."

export const useStripeSubmit = (
  data: ProductData,
  onError: (_error: string) => void
) => {
  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const handleError = (error?: StripeError | string) => {
    if (error) {
      onError(
        ('message' in (error as StripeError) &&
          (error as StripeError).message) ||
          errorMessage
      )
    }
  }

  const handleServerResponse = async (response: {
    error: any
    requires_action: any
    payment_intent_client_secret: any
  }) => {
    try {
      if (response.error) {
        // Show error from server on payment form
        throw new Error(response.error.message)
      }
      if (response.requires_action) {
        // Use Stripe.js to handle the required card action
        if (!stripe) {
          throw new Error('Stripe is not loaded')
        }

        const { error: errorAction, paymentIntent } =
          await stripe.handleCardAction(response.payment_intent_client_secret)

        if (errorAction) {
          // Show error from Stripe.js in payment form
          throw new Error(errorAction.message)
        }
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const serverResponse = await fetch(endpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            payment_intent_id: paymentIntent.id,
            ...data
          })
        })
        handleServerResponse(await serverResponse.json())
      } else {
        navigate('/boutique/success/')
      }
    } catch (error) {
      handleError(error as StripeError | string)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      if (elements == null || !elements.getElement(CardNumberElement)) {
        throw new Error("Stripe elements isn't loaded")
      }

      const card = elements.getElement(CardNumberElement)

      if (!card || !stripe) {
        throw new Error(
          'Stripe is not loaded or the card element is not loaded'
        )
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card
      })

      if (error || !paymentMethod) {
        throw new Error(error.message)
      }

      const serverResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          ...data
        })
      })
      handleServerResponse(await serverResponse.json())
    } catch (error) {
      handleError(error as StripeError | string)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleSubmit,
    isLoading
  }
}
