import colors from '../constants/colors'

const CloseIcon = ({ size = '20px', color = colors.black, ...props }) => (
  <div
    style={{
      width: size,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <svg
      height={size}
      viewBox='0 0 311 311.077'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill={color}
        d='M16.035 311.078c-4.097 0-8.195-1.558-11.308-4.695-6.25-6.25-6.25-16.383 0-22.633L283.789 4.687c6.25-6.25 16.383-6.25 22.633 0s6.25 16.383 0 22.637L27.363 306.383a16.045 16.045 0 01-11.328 4.695zm0 0'
      />
      <path
        fill={color}
        d='M295.117 311.078a15.879 15.879 0 01-11.308-4.695L4.727 27.324c-6.25-6.254-6.25-16.386 0-22.636s16.382-6.25 22.636 0L306.422 283.75c6.25 6.25 6.25 16.383 0 22.633-3.137 3.117-7.23 4.695-11.305 4.695zm0 0'
      />
    </svg>
  </div>
)

export default CloseIcon
