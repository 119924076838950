import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js'
import { FC } from 'react'
import styled from 'styled-components'

import { Label } from '../../common/styles'

import { Container } from './CheckoutSection.styles'

const CheckoutContainer = styled.div`
  width: 100%;
  margin-bottom: 21px;
`

const InputContainer = styled.div`
  border: solid 1px lightgray;
  padding: 14px;
  margin-bottom: 14px;
`

const style = {
  base: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'black',
    '::placeholder': {
      color: 'darkgrey',
      fontWeight: '400'
    }
  }
}

type Props = {
  resetError: any
}

const CheckoutSection: FC<Props> = ({ resetError }) => (
  <Container>
    <CheckoutContainer>
      <Label>Numéro de carte</Label>
      <InputContainer>
        <CardNumberElement
          onChange={resetError}
          options={{
            style
          }}
        />
      </InputContainer>
      <Label>Date d'expiration</Label>
      <InputContainer>
        <CardExpiryElement onChange={resetError} options={{ style }} />
      </InputContainer>
      <Label>Code de sécurité</Label>
      <InputContainer>
        <CardCvcElement onChange={resetError} options={{ style }} />
      </InputContainer>
    </CheckoutContainer>
  </Container>
)

export default CheckoutSection
